var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"d-flex flex-column px-6 pt-6 pb-0",staticStyle:{"overflowY":"auto","height":"100%","width":"100%","align-items":"center"},attrs:{"fluid":""}},[_c('v-col',{staticStyle:{"flex":"0","max-width":"1200px"}},[_c('v-row',{staticClass:"mt-0 mb-6 text-h5 font-weight-bold textBlack--text"},[_vm._v(" "+_vm._s(_vm.$t('deconve.details'))+" ")])],1),_c('v-col',[_c('v-container',{staticStyle:{"max-width":"400px"}},[_c('v-col',[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('button',{staticClass:"dropboxinput rounded",style:({
              border: _vm.profileImg? '0px' : '1px solid',
              borderColor: _vm.$vuetify.theme.themes.light.border,
              backgroundColor: _vm.profileImg? 'white' : _vm.$vuetify.theme.themes.light.boxBackground,
              height:'150px',
              width:'150px'
            }),attrs:{"elevation":"0"},on:{"mouseover":function($event){_vm.showAddImage = true},"mouseout":function($event){_vm.showAddImage = false}}},[(_vm.showAddImage || !_vm.profileImg)?_c('div',{staticClass:"\n                d-flex\n                flex-column\n                addImage\n                justify-center\n                align-center\n                rounded\n              ",staticStyle:{"background":"#F2F2F295","position":"absolute","flex":"1","width":"100%","height":"100%","flex-direction":"column","zIndex":"2","align-items":"center","backdrop-filter":"blur(4px)"}},[_c('v-icon',{staticClass:"mb-1",attrs:{"size":_vm.$vuetify.breakpoint.mobile? 32 : 36,"color":"neutral"}},[_vm._v(" mdi-image-outline ")]),_c('span',{staticClass:"neutral--text d-flex align-center font-weight-medium body-2 text-sm-body-1",staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(_vm.profileImg ? _vm.$t('deconve.setting.workspace.changeLogo') : _vm.$t('deconve.setting.workspace.addLogo'))+" ")])],1):_vm._e(),_c('input',{staticClass:"input-file-logo",attrs:{"type":"file","data-cy":"input-image","accept":"image/png, image/jpeg","required":"","disabled":!_vm.$can('update', 'com.deconve.workspace.settings')},on:{"change":function($event){return _vm.loadProfileImage($event.target.files)}}}),(_vm.profileImg)?_c('v-img',{staticClass:"rounded",style:({
                backgroundColor: 'white',
                borderRadius: '20px',
              }),attrs:{"src":_vm.profileImg,"contain":"","width":"120","height":"120"}}):_vm._e()],1)]),_c('v-row',{staticClass:"mt-8 mt-sm-16"},[_c('div',{staticStyle:{"flex":"1"}},[_c('span',{staticClass:"font-weight-medium caption text-sm-body-2 neutralPrimary--text"},[_vm._v(" "+_vm._s(_vm.$t('deconve.name' ))+" ")]),_c('input-text',{attrs:{"disabled":!_vm.$can('update', 'com.deconve.workspace.settings')},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1)]),_c('v-row',{staticClass:"mt-8 justify-end"},[_c('rectangle-button',{attrs:{"color":"primary","icon":"mdi-check","disabled":!_vm.hasWorkspaceChanged,"data-cy":"workspace-submit-button"},on:{"clicked":_vm.handleEditWorkspace}},[_vm._v(" "+_vm._s(_vm.$t('deconve.submit'))+" ")])],1)],1)],1)],1),_c('v-snackbar',{attrs:{"color":_vm.alertColor},scopedSlots:_vm._u([{key:"action",fn:function(ref){
              var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.alert = false}}},'v-btn',attrs,false),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-close ")])],1)]}}]),model:{value:(_vm.isAlertActive),callback:function ($$v) {_vm.isAlertActive=$$v},expression:"isAlertActive"}},[_vm._v(" "+_vm._s(_vm.alertMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }